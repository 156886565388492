import React from "react";
import Page from "../Page/Page";

function Intro() {
  return (
    <div>
      <h1>PRIVACY NOTICE </h1>
      <div className="indented">
        <p>Last updated</p>
        <p>February 10, 2021 </p>
        <p>
          Thank you for choosing to be part of our community at Human App Maker
          ("Company", "we", "us", "our"). We are committed to protecting your
          personal information and your right to privacy. If you have any
          questions or concerns about this privacy notice, or our practices with
          regards to your personal information, please contact us at
          humanappmaker@gmail.com.
        </p>
        <p>
          When you visit our website
          <a
            href="http://www.biblesc.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            http://www.biblesc.com
          </a>
          (the "Website"), use our mobile application, as the case may be (the
          "App") and more generally, use any of our services (the "Services",
          which include the  Website and App), we appreciate that you are
          trusting us with your personal information. We take your privacy very
          seriously. In this privacy notice, we seek to explain to you in the
          clearest way possible what information we collect, how we use it and
          what rights you have in relation to it. We hope you take some time to
          read through it carefully, as it is important.{" "}
        </p>
        <p>
          If there are any terms in this privacy notice that you do not agree
          with, please discontinue use of our Services immediately. This privacy
          notice applies to all information collected through our Services
          (which, as described above, includes our  Website and App ), as well
          as, any related services, sales, marketing or events.
        </p>
        <p>
          Please read this privacy notice carefully as it will help you
          understand what we do with the information that we collect.
        </p>
      </div>
    </div>
  );
}

function TableOfContents() {
  return (
    <div>
      <h2 id="table-of-contents">TABLE OF CONTENTS</h2>
      <div className="indented">
        <ol>
          <a href="#infocollect">
            <li>WHAT INFORMATION DO WE COLLECT?</li>
          </a>

          <a href="#infoshare">
            <li>WILL YOUR INFORMATION BE SHARED WITH ANYONE?</li>
          </a>

          <a href="#intltransfers">
            <li> IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</li>
          </a>
          <a href="#inforetain">
            <li>HOW LONG DO WE KEEP YOUR INFORMATION?</li>
          </a>
          <a href="#privacyrights">
            <li>WHAT ARE YOUR PRIVACY RIGHTS?</li>
          </a>
          <a href="#DNT">
            <li>CONTROLS FOR DO-NOT-TRACK FEATURES</li>
          </a>
          <a href="#caresidents">
            <li> DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</li>
          </a>
          <a href="#policyupdates">
            <li>DO WE MAKE UPDATES TO THIS NOTICE?</li>
          </a>
          <a href="#contact">
            <li>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</li>
          </a>
          <a href="#request">
            <li>
              HOW CAN YOU REVIEW, UPDATE OR DELETE THE DATA WE COLLECT FROM YOU?
            </li>
          </a>
        </ol>
      </div>
    </div>
  );
}

function Section1() {
  return (
    <div>
      <a href="#table-of-contents">
        <h3 id="infocollect">1. WHAT INFORMATION DO WE COLLECT?</h3>
      </a>
      <div className="indented">
        <p>Information collected through our App</p>
        <p>In Short:</p>
        <p>
          We collect information regarding your push notifications, when you use
          our App.
        </p>
        <p>If you use our App, we also collect the following information:</p>
        <p>
          Push Notifications.  We may request to send you push notifications
          regarding your account or certain features of the App. If you wish to
          opt-out from receiving these types of communications, you may turn
          them off in your device's settings.
        </p>
        <p>
          This information is primarily needed to maintain the security and
          operation of our App, for troubleshooting and for our internal
          analytics and reporting purposes.
        </p>
      </div>
    </div>
  );
}

function Section2() {
  return (
    <div>
      <a href="#table-of-contents">
        <h3 id="infoshare">2. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</h3>
      </a>
      <div className="indented">
        <p>In Short:</p>
        <p>
          We only share information with your consent, to comply with laws, to
          provide you with services, to protect your rights, or to fulfill
          business obligations.
        </p>
        <p>
          We may process or share your data that we hold based on the following
          legal basis:
        </p>
        <p>Consent:</p>
        <p>
          We may process your data if you have given us specific consent to use
          your personal information for a specific purpose.
        </p>
        <p>Legitimate Interests:</p>
        <p>
          We may process your data when it is reasonably necessary to achieve
          our legitimate business interests.
        </p>
        <p>Performance of a Contract:</p>
        <p>
          Where we have entered into a contract with you, we may process your
          personal information to fulfill the terms of our contract.
        </p>
        <p>Legal Obligations:</p>
        <p>
          We may disclose your information where we are legally required to do
          so in order to comply with applicable law, governmental requests, a
          judicial proceeding, court order, or legal process, such as in
          response to a court order or a subpoena (including in response to
          public authorities to meet national security or law enforcement
          requirements).
        </p>
        <p>Vital Interests:</p>
        <p>
          We may disclose your information where we believe it is necessary to
          investigate, prevent, or take action regarding potential violations of
          our policies, suspected fraud, situations involving potential threats
          to the safety of any person and illegal activities, or as evidence in
          litigation in which we are involved.
        </p>
        <p>
          More specifically, we may need to process your data or share your
          personal information in the following situations:
        </p>
        <p>Business Transfers.</p>
        <p>
          We may share or transfer your information in connection with, or
          during negotiations of, any merger, sale of company assets, financing,
          or acquisition of all or a portion of our business to another company.
        </p>
      </div>
    </div>
  );
}

function Section3() {
  return (
    <div>
      <a href="#table-of-contents">
        <h3 id="intltransfers">
          3. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
        </h3>
      </a>
      <div className="indented">
        <p>In Short:</p>
        <p>
          We may transfer, store, and process your information in countries
          other than your own.
        </p>
        <p>
          Our servers are located in. If you are accessing our Services from
          outside, please be aware that your information may be transferred to,
          stored, and processed by us in our facilities and by those third
          parties with whom we may share your personal information (see "
          <a href="#infoshare">WILL YOUR INFORMATION BE SHARED WITH ANYONE?</a>"
          above), in and other countries.
        </p>
        <p>
          If you are a resident in the European Economic Area, then these
          countries may not necessarily have data protection laws or other
          similar laws as comprehensive as those in your country. We will
          however take all necessary measures to protect your personal
          information in accordance with this privacy notice and applicable law.
        </p>
      </div>
    </div>
  );
}

function Section4() {
  return (
    <div>
      <a href="#table-of-contents">
        <h3 id="inforetain">4. HOW LONG DO WE KEEP YOUR INFORMATION?</h3>
      </a>
      <div className="indented">
        <p>In Short:</p>
        <p>
          We keep your information for as long as necessary to fulfill the
          purposes outlined in this privacy notice unless otherwise required by
          law.
        </p>
        <p>
          We will only keep your personal information for as long as it is
          necessary for the purposes set out in this privacy notice, unless a
          longer retention period is required or permitted by law (such as tax,
          accounting or other legal requirements). No purpose in this notice
          will require us keeping your personal information for longer than
          __________ .
        </p>
        <p>
          When we have no ongoing legitimate business need to process your
          personal information, we will either delete or anonymize such
          information, or, if this is not possible (for example, because your
          personal information has been stored in backup archives), then we will
          securely store your personal information and isolate it from any
          further processing until deletion is possible.
        </p>
      </div>
    </div>
  );
}

function Section5() {
  return (
    <div>
      <a href="#table-of-contents">
        <h3 id="privacyrights">5. WHAT ARE YOUR PRIVACY RIGHTS?</h3>
      </a>
      <div className="indented">
        <p>In Short:</p>
        <p>You may review, change, or terminate your account at any time. </p>
        <p>
          If you are a resident in the European Economic Area and you believe we
          are unlawfully processing your personal information, you also have the
          right to complain to your local data protection supervisory authority.
          You can find their contact details here:
          <a
            href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
            rel="noopener noreferrer"
            target="_blank"
          >
            http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
          </a>
          .
        </p>
        <p>
          If you are a resident in Switzerland, the contact details for the data
          protection authorities are available here:
          <a
            href="https://www.edoeb.admin.ch/edoeb/en/home.html"
            rel="noopener noreferrer"
            target="_blank"
          >
            https://www.edoeb.admin.ch/edoeb/en/home.html
          </a>
          .
        </p>
      </div>
    </div>
  );
}

function Section6() {
  return (
    <div>
      <a href="#table-of-contents">
        <h3 id="DNT">6. CONTROLS FOR DO-NOT-TRACK FEATURES</h3>
      </a>
      <div className="indented">
        <p>
          Most web browsers and some mobile operating systems and mobile
          applications include a Do-Not-Track ("DNT") feature or setting you can
          activate to signal your privacy preference not to have data about your
          online browsing activities monitored and collected. At this stage no
          uniform technology standard for recognizing and implementing DNT
          signals has been finalized. As such, we do not currently respond to
          DNT browser signals or any other mechanism that automatically
          communicates your choice not to be tracked online. If a standard for
          online tracking is adopted that we must follow in the future, we will
          inform you about that practice in a revised version of this privacy
          notice.  
        </p>
      </div>
    </div>
  );
}

function Section7() {
  return (
    <div>
      <a href="#table-of-contents">
        <h3 id="caresidents">
          7. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
        </h3>
      </a>
      <div className="indented">
        <p>In Short:</p>
        <p>
          Yes, if you are a resident of California, you are granted specific
          rights regarding access to your personal information.
        </p>
        <p>
          California Civil Code Section 1798.83, also known as the "Shine The
          Light" law, permits our users who are California residents to request
          and obtain from us, once a year and free of charge, information about
          categories of personal information (if any) we disclosed to third
          parties for direct marketing purposes and the names and addresses of
          all third parties with which we shared personal information in the
          immediately preceding calendar year. If you are a California resident
          and would like to make such a request, please submit your request in
          writing to us using the contact information provided below.
        </p>
        <p>
          If you are under 18 years of age, reside in California, and have a
          registered account with a Service, you have the right to request
          removal of unwanted data that you publicly post on the Services. To
          request removal of such data, please contact us using the contact
          information provided below, and include the email address associated
          with your account and a statement that you reside in California. We
          will make sure the data is not publicly displayed on the Services, but
          please be aware that the data may not be completely or comprehensively
          removed from all our systems (e.g. backups, etc.).
        </p>
      </div>
    </div>
  );
}

function Section8() {
  return (
    <div>
      <a href="#table-of-contents">
        <h3 id="policyupdates">8. DO WE MAKE UPDATES TO THIS NOTICE? </h3>
      </a>
      <div className="indented">
        <p>In Short:</p>
        <p>
          Yes, we will update this notice as necessary to stay compliant with
          relevant laws.
        </p>
        <p>
          We may update this privacy notice from time to time. The updated
          version will be indicated by an updated "Revised" date and the updated
          version will be effective as soon as it is accessible. If we make
          material changes to this privacy notice, we may notify you either by
          prominently posting a notice of such changes or by directly sending
          you a notification. We encourage you to review this privacy notice
          frequently to be informed of how we are protecting your information.
        </p>
      </div>
    </div>
  );
}

function Section9() {
  return (
    <div>
      <a href="#table-of-contents">
        <h3 id="contact">9. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h3>     
      </a>
      <div className="indented">
        <p>
          If you have questions or comments about this notice, you may email us
          at
          <a href="mailto:HumanAppMaker@gmail.com">HumanAppMaker@gmail.com</a>
        </p>
      </div>
    </div>
  );
}

function Section10() {
  return (
    <div>
      <a href="#table-of-contents">
        <h3 id="request">
          10. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
          YOU?
        </h3>
      </a>
      <div className="indented">
        <p>
          Based on the applicable laws of your country, you may have the right
          to request access to the personal information we collect from you,
          change that information, or delete it in some circumstances. To
          request to review, update, or delete your personal information, please
          submit a request form by clicking
          <a
            href="https://app.termly.io/notify/8d7200c0-aab4-4246-9132-69bafbd7fe30"
            rel="noopener noreferrer"
            target="_blank"
          >
            here
          </a>
          . We will respond to your request within 30 days.
        </p>
        <p>
          This privacy policy was created using
          <a
            href="https://termly.io/products/privacy-policy-generator/?ftseo"
            rel="noopener noreferrer"
            target="_blank"
          >
            Termly’s Privacy Policy Generator
          </a>
          .
        </p>
      </div>
    </div>
  );
}

export default function PrivacyPage() {
  return (
    <Page>
      <Intro />
      <div className="indented">
        <TableOfContents />
        <Section1 />
        <Section2 />
        <Section3 />
        <Section4 />
        <Section5 />
        <Section6 />
        <Section7 />
        <Section8 />
        <Section9 />
        <Section10 />
      </div>
    </Page>
  );
}
